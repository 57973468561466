import { Preloader } from "../../Preloader/Preloader";
import "./TextButton.css";

interface TextButtonProps {
  type?: "button" | "submit";
  text: string;
  action?: () => void;
  margin?: string;
  fontSize?: string;
  fontWeight?: number;
  color?: "black" | "white";
  disabled?: boolean;
  preloader?: boolean;
}

export const TextButton = ({
  type = "button",
  text,
  action,
  margin,
  fontSize,
  fontWeight,
  color,
  disabled = false,
  preloader = false,
}: TextButtonProps) => {
  const styles = {
    margin: margin || "0",
  };

  const stylesText = {
    fontSize: fontSize || "1.2rem",
    fontWeight: fontWeight || 500,
    opacity: preloader ? 0 : 1,
  };

  return (
    <button
      type={type}
      className={`text-button${color ? "-" + color : ""}`}
      onClick={action}
      style={styles}
      disabled={disabled || preloader}
    >
      <p style={stylesText}>{text}</p>
      {preloader && (
        <div className="text-button-preloader">
          <Preloader size="small" />
        </div>
      )}
    </button>
  );
};
