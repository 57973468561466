import { useAppSelector } from "../../../services/hooks/useAppHooks";
import { menuItems } from "../../../utils/menuData";
import { NavItem } from "../../ui/NavItem/NavItem";
import "./Navigation.css";

export const Navigation = () => {
  const { user } = useAppSelector((state) => state.auth);

  return (
    <nav className="navigation">
      {menuItems &&
        menuItems.map(
          (item, index) =>
            user &&
            user.role &&
            item.roles.includes(user.role) && (
              <NavItem
                key={index}
                title={item.title}
                link={item.link}
                icon={item.icon}
              />
            ),
        )}
    </nav>
  );
};
