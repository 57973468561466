import { Icon } from "../../images/Icon/Icon";
import "./IconButton.css";

interface IconButtonProps {
  type?: "button" | "submit";
  icon: string;
  action: () => void;
  margin?: string;
  width?: string;
  color?: "violet";
}

export const IconButton = ({
  type = "button",
  icon,
  action,
  margin,
  width,
  color,
}: IconButtonProps) => {
  const styles = {
    margin: margin || "0",
  };

  return (
    <button
      type={type}
      onClick={action}
      style={styles}
      className={color ? `icon-button-${color}` : ""}
    >
      <Icon icon={icon} width={width} />
    </button>
  );
};
