import { Logo } from "../ui/Logo/Logo";
import { FormWrap } from "../ui/wrappers/FormWrap/FormWrap";
import { LoginForm } from "../view/LoginForm/LoginForm";

export const Login = () => {
  return (
    <FormWrap>
      <Logo fontSize={3} />
      <LoginForm />
    </FormWrap>
  );
};
