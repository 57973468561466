import { useEffect, useState } from "react";
import { TextButton } from "../../ui/buttons/TextButton/TextButton";
import { TextInput } from "../../ui/inputs/TextInput/TextInput";
import { ButtonWrap } from "../../ui/wrappers/ButtonWrap/ButtonWrap";
import ModalWrap from "../../ui/wrappers/ModalWrap/ModalWrap";
import "./TypeModal.css";
import {
  useCreateProductTypeMutation,
  useGetProductTypeByIdQuery,
  useUpdateProductTypeMutation,
} from "../../../store/productTypes/productTypesApi";
import { TextError } from "../../ui/errors/TextError/TextError";
import LoadBlock from "../LoadBlock/LoadBlock";

interface FormErrors {
  name?: string;
}

interface TypeModalProps {
  uuidItem?: string;
  closeModal: () => void;
  updatePage: () => void;
}

export const TypeModal = ({
  uuidItem,
  closeModal,
  updatePage,
}: TypeModalProps) => {
  const [name, setName] = useState("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [createProductType, { isLoading: isCreating, error: createError }] =
    useCreateProductTypeMutation();
  const [updateProductType, { isLoading: isUpdating, error: updateError }] =
    useUpdateProductTypeMutation();
  const { data: fullProductType, isLoading: isFullLoading } =
    useGetProductTypeByIdQuery(uuidItem ?? "", {
      skip: !uuidItem,
      refetchOnMountOrArgChange: true,
    });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);

    if (!value) {
      setFormErrors((prev) => ({
        ...prev,
        name: "Название не может быть пустым",
      }));
    } else {
      setFormErrors((prev) => {
        const { name, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (uuidItem) {
        await updateProductType({ uuid: uuidItem, name }).unwrap();
      } else {
        await createProductType({ name }).unwrap();
      }
      updatePage();
      closeModal();
    } catch (err) {
      console.error("Ошибка при сохранении типа вещи", err);
    }
  };

  useEffect(() => {
    if (uuidItem && fullProductType) {
      setName(fullProductType.name);
    } else if (!uuidItem) {
      setName("");
    }
    setFormErrors({});
  }, [uuidItem, fullProductType]);

  return (
    <ModalWrap
      action={closeModal}
      title={`${uuidItem ? "Редактировать" : "Добавить новый"} тип`}
    >
      {isFullLoading ? (
        <LoadBlock />
      ) : (
        <form className="type-modal" onSubmit={handleSubmit}>
          <TextInput
            name="name"
            label="Название типа"
            width="30rem"
            margin="2rem 0 0 0"
            value={name}
            action={handleNameChange}
            textError={formErrors.name}
          />
          <ButtonWrap>
            <TextButton
              type="submit"
              text="Сохранить"
              color="black"
              margin="2rem 0 0 0"
              disabled={Object.keys(formErrors).length > 0 || !name}
              preloader={isCreating || isUpdating}
            />
            <TextButton
              type="button"
              text="Отмена"
              color="white"
              margin="2rem 0 0 0"
              action={closeModal}
            />
          </ButtonWrap>
        </form>
      )}
      <TextError text={JSON.stringify(createError || updateError)} />
    </ModalWrap>
  );
};
