import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface Collection {
  uuid: string;
  name: string;
}

export const collectionsApi = createApi({
  reducerPath: "collectionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ADMIN_API_URL, // "https://api.miyukon.store/admin"
    credentials: "include",
  }),
  endpoints: (builder) => ({
    getCollections: builder.query<Collection[], void>({
      query: () => "/collections",
      transformResponse: (response: {
        status: string;
        message: string;
        data: Collection[];
      }) => response.data,
    }),
    getCollectionById: builder.query<Collection, string>({
      query: (uuid) => `/collections/${uuid}`,
      transformResponse: (response: {
        status: string;
        message: string;
        data: Collection;
      }) => response.data,
    }),
    createCollection: builder.mutation<Collection, { name: string }>({
      query: (newCollection) => ({
        url: "/collections",
        method: "POST",
        body: newCollection,
      }),
      transformResponse: (response: {
        status: string;
        message: string;
        data: Collection;
      }) => response.data,
    }),
    updateCollection: builder.mutation<
      Collection,
      { uuid: string; name: string }
    >({
      query: ({ uuid, name }) => ({
        url: `/collections/${uuid}`,
        method: "PATCH",
        body: { name },
      }),
      transformResponse: (response: {
        status: string;
        message: string;
        data: Collection;
      }) => response.data,
    }),
    deleteCollection: builder.mutation<{ uuid: string }, string>({
      query: (uuid) => ({
        url: `/collections/${uuid}`,
        method: "DELETE",
      }),
      transformResponse: (_: any, __: any, arg: string) => ({ uuid: arg }),
    }),
  }),
});

export const {
  useGetCollectionsQuery,
  useGetCollectionByIdQuery,
  useCreateCollectionMutation,
  useUpdateCollectionMutation,
  useDeleteCollectionMutation,
} = collectionsApi;
