import { useState } from "react";
import { IconButton } from "../../ui/buttons/IconButton/IconButton";
import { CardTitle } from "../../ui/text/CardTitle/CardTitle";
import { BorderWrap } from "../../ui/wrappers/BorderWrap/BorderWrap";
import dots from "../../../assets/icons/dots.png";
import "./CollectionItem.css";
import { TextButton } from "../../ui/buttons/TextButton/TextButton";
import { useAppSelector } from "../../../services/hooks/useAppHooks";
import { useDeleteCollectionMutation } from "../../../store/collections/collectionsApi";
import { Collection } from "../../../services/types/collection.type";

interface CollectionItemProps {
  item: Collection;
  editAction: (uuid: string) => void;
  updatePage: () => void;
}

export const CollectionItem = ({
  item,
  editAction,
  updatePage,
}: CollectionItemProps) => {
  const { user } = useAppSelector((state) => state.auth);
  const [showMenu, setShowMenu] = useState(false);
  const [deleteCollection, { isLoading: isDeleting, error: deleteError }] =
    useDeleteCollectionMutation();

  const menuClick = () => {
    setShowMenu((showMenu) => !showMenu);
  };

  const editItem = () => {
    editAction(item.uuid);
    setShowMenu(false);
  };

  const deleteItem = async () => {
    if (window.confirm("Вы действительно хотите удалить этоту коллекцию?")) {
      try {
        await deleteCollection(item.uuid).unwrap();
        updatePage();
      } catch (err) {
        console.error("Ошибка удаления коллекции", err);
      }
    }
  };

  return (
    <BorderWrap>
      <div className="collection-item-menu-wrap">
        <IconButton icon={dots} action={menuClick} color="violet" />
        {showMenu && (
          <div className="collection-item-menu">
            <BorderWrap color="violet">
              <TextButton text="Редактировать" action={editItem} />
              {user && (user.role === "admin" || user.role === "superadmin") ? (
                <TextButton
                  text="Удалить"
                  margin="1rem 0 0 0"
                  action={deleteItem}
                />
              ) : (
                <></>
              )}
            </BorderWrap>
          </div>
        )}
      </div>
      <CardTitle title={item.name} margin="1rem 0 0" />
    </BorderWrap>
  );
};
