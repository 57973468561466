import { Preloader } from "../../ui/Preloader/Preloader";
import BlockWrap from "../../ui/wrappers/BlockWrap/BlockWrap";
import "./LoadBlock.css";

const LoadBlock = () => {
  return (
    <BlockWrap>
      <Preloader />
    </BlockWrap>
  );
};

export default LoadBlock;
