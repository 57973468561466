import { JSX } from "react";
import "./BorderWrap.css";

interface BorderWrapProps {
  children: string | JSX.Element | JSX.Element[] | null;
  color?: "white" | "violet" | "yellow";
}

export const BorderWrap = ({ children, color = "white" }: BorderWrapProps) => {
  return <div className={`border-wrap border-wrap-${color}`}>{children}</div>;
};
