import { NoPageInfo } from "../ui/errors/NoPage/NoPageInfo";
import { FormWrap } from "../ui/wrappers/FormWrap/FormWrap";

export const NoPage = () => {
  return (
    <FormWrap>
      <NoPageInfo />
    </FormWrap>
  );
};
