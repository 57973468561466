import { UnauthorizedInfo } from "../ui/errors/Unauthorized/UnauthorizedInfo";
import { FormWrap } from "../ui/wrappers/FormWrap/FormWrap";

export const Unauthorized = () => {
  return (
    <FormWrap>
      <UnauthorizedInfo />
    </FormWrap>
  );
};
