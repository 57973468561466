import "./CardTitle.css";

interface CardTitleProps {
  title: string;
  margin?: string;
}

export const CardTitle = ({ title, margin }: CardTitleProps) => {
  const styles = {
    margin: margin || "0 0 1rem 0",
  };

  return (
    <p className="card-title" style={styles}>
      {title}
    </p>
  );
};
