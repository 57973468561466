import { useState } from "react";
import { GridWrap } from "../ui/wrappers/GridWrap/GridWrap";
import { TypeItem } from "../view/TypeItem/TypeItem";
import { useGetProductTypesQuery } from "../../store/productTypes/productTypesApi";
import LoadBlock from "../view/LoadBlock/LoadBlock";
import { TextError } from "../ui/errors/TextError/TextError";
import { TypeModal } from "../view/TypeModal/TypeModal";
import { TextButton } from "../ui/buttons/TextButton/TextButton";

export const Types = () => {
  const {
    data: productTypes,
    error,
    isLoading,
    refetch,
  } = useGetProductTypesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [editingType, setEditingType] = useState<string | undefined>(undefined);
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => setOpenModal(false);

  const addModal = () => {
    setEditingType(undefined);
    setOpenModal(true);
  };

  const editModal = (uuid: string) => {
    setEditingType(uuid);
    setOpenModal(true);
  };

  return (
    <>
      {isLoading ? (
        <LoadBlock />
      ) : error ? (
        <TextError text="Ошибка при загрузке" />
      ) : (
        <>
          <TextButton
            text="Добавить новый тип"
            color="black"
            margin="0 0 2rem 0"
            action={addModal}
          />
          <GridWrap>
            {productTypes && productTypes.length > 0
              ? productTypes.map((item) => (
                  <TypeItem
                    key={item.uuid}
                    item={item}
                    editAction={editModal}
                    updatePage={refetch}
                  />
                ))
              : null}
          </GridWrap>
        </>
      )}
      {openModal && (
        <TypeModal
          uuidItem={editingType}
          closeModal={closeModal}
          updatePage={refetch}
        />
      )}
    </>
  );
};
