import { JSX } from "react";
import "./ButtonWrap.css";

interface ButtonWrapProps {
  children: string | JSX.Element | JSX.Element[] | null;
}

export const ButtonWrap = ({ children }: ButtonWrapProps) => {
  return <div className="button-wrap">{children}</div>;
};
