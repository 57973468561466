import { BorderWrap } from "../ui/wrappers/BorderWrap/BorderWrap";
import { GridWrap } from "../ui/wrappers/GridWrap/GridWrap";

export const Dashboard = () => {
  return (
    <GridWrap>
      <BorderWrap color="violet">
        <div style={{height: "20rem"}}></div>
      </BorderWrap>
    </GridWrap>
  );
};
