import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import { authApi } from "./auth/authApi";
import { productsApi } from "./products/productsApi";
import { collectionsApi } from "./collections/collectionsApi";
import { productTypesApi } from "./productTypes/productTypesApi";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [collectionsApi.reducerPath]: collectionsApi.reducer,
    [productTypesApi.reducerPath]: productTypesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      productsApi.middleware,
      collectionsApi.middleware,
      productTypesApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
