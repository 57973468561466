import { JSX } from "react";
import "./PageWrap.css";

interface GridWrapProps {
  children: string | JSX.Element | JSX.Element[] | null;
}

export const PageWrap = ({ children }: GridWrapProps) => {
  return <main className="page-wrap">{children}</main>;
};
