import { Link } from "react-router";
import "./NoPageInfo.css";

export const NoPageInfo = () => {
  return (
    <>
      <p className="no-page-404">404</p>
      <Link to="/" className="no-page-link">
        На главную
      </Link>
    </>
  );
};
