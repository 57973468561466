import { JSX } from "react";
import "./ContentWrap.css";

interface ContentWrapProps {
  children: string | JSX.Element | JSX.Element[] | null;
}

export const ContentWrap = ({ children }: ContentWrapProps) => {
  return <section className="content-wrap">{children}</section>;
};
