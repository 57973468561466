import { useState } from "react";
import { TextButton } from "../../ui/buttons/TextButton/TextButton";
import { TextInput } from "../../ui/inputs/TextInput/TextInput";
import "./LoginForm.css";
import { useLoginMutation } from "../../../store/auth/authApi";
import { useAppDispatch } from "../../../services/hooks/useAppHooks";
import { useNavigate } from "react-router";
import { setCredentials } from "../../../store/auth/authSlice";
import { TextError } from "../../ui/errors/TextError/TextError";

interface FormErrors {
  email?: string;
  password?: string;
}

export const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [login, { isLoading, error: loginError }] = useLoginMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Функция динамической валидации email
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    if (!value) {
      setFormErrors((prev) => ({ ...prev, email: "Email обязателен" }));
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setFormErrors((prev) => ({
          ...prev,
          email: "Некорректный формат email",
        }));
      } else {
        setFormErrors((prev) => {
          const { email, ...rest } = prev;
          return rest;
        });
      }
    }
  };

  // Функция динамической валидации пароля
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    if (!value) {
      setFormErrors((prev) => ({ ...prev, password: "Пароль обязателен" }));
    } else if (value.length < 6) {
      setFormErrors((prev) => ({
        ...prev,
        password: "Пароль должен содержать не менее 6 символов",
      }));
    } else {
      setFormErrors((prev) => {
        const { password, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Финальная проверка на наличие ошибок
    if (Object.keys(formErrors).length > 0 || !email || !password) {
      return;
    }

    try {
      const result = await login({ email, password }).unwrap();
      dispatch(setCredentials({ user: result }));
      navigate("/");
    } catch (err) {
      console.error("Ошибка входа", err);
    }
  };

  return (
    <form className="login-form" onSubmit={handleSubmit} noValidate>
      <TextInput
        type="email"
        name="email"
        label="Почта"
        value={email}
        action={handleEmailChange}
        textError={formErrors.email}
      />
      <TextInput
        type="password"
        name="password"
        label="Пароль"
        value={password}
        action={handlePasswordChange}
        textError={formErrors.password}
      />
      <TextButton
        type="submit"
        text="Войти"
        color="black"
        margin="2rem 0 0 0"
        disabled={Object.keys(formErrors).length > 0 || !email || !password}
        preloader={isLoading}
      />
      <TextError text={JSON.stringify(loginError)} />
    </form>
  );
};
