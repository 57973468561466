import "./Icon.css";

interface IconProps {
  icon: string;
  width?: string;
}

export const Icon = ({ icon, width }: IconProps) => {
  const styles = {
    width: width || "1.8rem",
  };

  return (
    <div className="icon" style={styles}>
      <img src={icon} alt="icon" />
    </div>
  );
};
