import { useState } from "react";
import { GridWrap } from "../ui/wrappers/GridWrap/GridWrap";
import { TextButton } from "../ui/buttons/TextButton/TextButton";
import LoadBlock from "../view/LoadBlock/LoadBlock";
import { TextError } from "../ui/errors/TextError/TextError";
import { useGetCollectionsQuery } from "../../store/collections/collectionsApi";
import { CollectionItem } from "../view/CollectionItem/CollectionItem";
import { CollectionModal } from "../view/CollectionModal/CollectionModal";

export const Collections = () => {
  const {
    data: collections,
    error,
    isLoading,
    refetch,
  } = useGetCollectionsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [editingCollection, setEditingCollection] = useState<
    string | undefined
  >(undefined);
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => setOpenModal(false);

  const addModal = () => {
    setEditingCollection(undefined);
    setOpenModal(true);
  };

  const editModal = (uuid: string) => {
    setEditingCollection(uuid);
    setOpenModal(true);
  };

  return (
    <>
      {isLoading ? (
        <LoadBlock />
      ) : error ? (
        <TextError text="Ошибка при загрузке" />
      ) : (
        <>
          <TextButton
            text="Добавить новую коллекцию"
            color="black"
            margin="0 0 2rem 0"
            action={addModal}
          />
          <GridWrap>
            {collections && collections.length > 0
              ? collections.map((item) => (
                  <CollectionItem
                    key={item.uuid}
                    item={item}
                    editAction={editModal}
                    updatePage={refetch}
                  />
                ))
              : null}
          </GridWrap>
        </>
      )}
      {openModal && (
        <CollectionModal
          uuidItem={editingCollection}
          closeModal={closeModal}
          updatePage={refetch}
        />
      )}
    </>
  );
};
