// src/store/auth/authApi.ts
import {
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { logout } from "./authSlice";

// Базовый запрос с настройками
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AUTH_API_URL, // например, "https://api.miyukon.store/auth"
  credentials: "include", // отправляем cookies
  prepareHeaders: (headers) => {
    return headers;
  },
});

// Обёртка для базового запроса, которая проверяет наличие ошибки 401.
// Если получен 401, вызывается logout.
const baseQueryWithLogout = async (
  args: string | any,
  api: any,
  extraOptions: any,
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // Если сервер возвращает 401, это означает, что сессия недействительна
    api.dispatch(logout());
  }
  return result;
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithLogout,
  endpoints: (builder) => ({
    // Логин пользователя
    login: builder.mutation<any, { email: string; password: string }>({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (response: {
        status: string;
        message: string;
        data: { user: any };
      }) => response.data.user,
    }),
    // Получение данных текущего пользователя через GET /auth/me
    getUser: builder.query<any, void>({
      query: () => ({
        url: "/me",
        method: "GET",
      }),
      transformResponse: (response: {
        status: string;
        message: string;
        data: any;
      }) => response.data,
    }),
    // Обновление токенов
    refresh: builder.mutation<any, void>({
      query: () => ({
        url: "/refresh-token",
        method: "POST",
      }),
      transformResponse: (response: { status: string; data: { user: any } }) =>
        response.data.user,
    }),
    // Выход из системы
    logout: builder.mutation<any, void>({
      query: () => ({
        url: "/logout",
        method: "POST",
      }),
      transformResponse: (response: { status: string; message: string }) =>
        response.message,
    }),
    // Обновление профиля пользователя
    updateProfile: builder.mutation<
      any,
      {
        firstName: string;
        lastName: string;
        phone: string;
        dateOfBirth: string;
      }
    >({
      query: (payload) => ({
        url: "/update-profile",
        method: "POST",
        body: payload,
      }),
      transformResponse: (response: { status: string; data: { user: any } }) =>
        response.data.user,
    }),
  }),
});

export const {
  useLoginMutation,
  useGetUserQuery,
  useRefreshMutation,
  useLogoutMutation,
  useUpdateProfileMutation,
} = authApi;
