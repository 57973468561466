import { Outlet } from "react-router";
import { Header } from "../view/Header/Header";
import { PageWrap } from "../ui/wrappers/PageWrap/PageWrap";
import { Navigation } from "../view/Navigation/Navigation";
import { ContentWrap } from "../ui/wrappers/ContentWrap/ContentWrap";

export const BaseLayout = () => {
  return (
    <PageWrap>
      <Header />
      <Navigation />
      <ContentWrap>
        <Outlet />
      </ContentWrap>
    </PageWrap>
  );
};
