import {
  useAppDispatch,
  useAppSelector,
} from "../../../services/hooks/useAppHooks";
//import { AccountPhoto } from "../../ui/images/AccountPhoto/AccountPhoto";
import "./Account.css";
import arrow from "../../../assets/icons/arrow.png";
import { useState } from "react";
import { BorderWrap } from "../../ui/wrappers/BorderWrap/BorderWrap";
import { IconButton } from "../../ui/buttons/IconButton/IconButton";
import { TextButton } from "../../ui/buttons/TextButton/TextButton";
import { useLogoutMutation } from "../../../store/auth/authApi";
import { useNavigate } from "react-router";
import { logout } from "../../../store/auth/authSlice";

export const Account = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logoutMutation, { isLoading }] = useLogoutMutation();

  const showClick = () => setShowMenu((showMenu) => !showMenu);

  const handleLogout = async () => {
    try {
      await logoutMutation().unwrap();
      dispatch(logout());
      navigate("/login");
    } catch (err) {
      console.error("Ошибка при выходе из системы", err);
      // Можно добавить уведомление об ошибке для пользователя
    }
  };

  return (
    <div className="account">
      {/*<AccountPhoto />*/}
      <div className="account-info">
        <p className="account-info-name">
          {user?.firstName} {user?.lastName}
        </p>
        <p className="account-info-role">{user?.role}</p>
      </div>
      <div
        style={{
          transform: showMenu ? "rotate(180deg)" : "",
          transition: ".3s",
        }}
      >
        <IconButton icon={arrow} action={showClick} />
      </div>
      {showMenu && (
        <div className="account-menu">
          <BorderWrap color="violet">
            <TextButton text="Выйти" action={handleLogout} />
          </BorderWrap>
        </div>
      )}
    </div>
  );
};
