import React, { useEffect } from "react";
import { useGetUserQuery } from "../../store/auth/authApi";
import { useAppDispatch } from "../../services/hooks/useAppHooks";
import { setCredentials, setLoading } from "../../store/auth/authSlice";
import { LoadPage } from "../pages/LoadPage";

interface AuthInitializerProps {
  children: React.ReactNode;
}

const AuthInitializer = ({ children }: AuthInitializerProps) => {
  const dispatch = useAppDispatch();
  const {
    data: user,
    isSuccess,
    isError,
    isLoading,
  } = useGetUserQuery(undefined);

  useEffect(() => {
    if (isSuccess && user.user) {
      dispatch(setCredentials({ user: user.user }));
    } else if (isError) {
      dispatch(setLoading(false));
    }
  }, [isSuccess, user, isError, dispatch]);

  if (isLoading) {
    return <LoadPage />;
  }

  return <>{children}</>;
};

export default AuthInitializer;
