import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../services/hooks/useAppHooks";
import { JSX } from "react";
import { LoadPage } from "../pages/LoadPage";

interface PublicRouteProps {
  children: JSX.Element;
}

const PublicRoute = ({ children }: PublicRouteProps) => {
  const { isAuthenticated, isLoading } = useAppSelector((state) => state.auth);
  const location = useLocation();

  if (isLoading) {
    return <LoadPage />;
  }

  if (isAuthenticated && location.pathname === "/login") {
    const from = (location.state as { from?: string } | undefined)?.from || "/";
    return <Navigate to={from} replace />;
  }

  return children;
};

export default PublicRoute;
