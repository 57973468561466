import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  CreateProductRequest,
  Product,
  UpdateProductRequest,
} from "../../services/types/product.type";

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ADMIN_API_URL, // Например, "https://api.miyukon.store/admin"
    credentials: "include",
  }),
  endpoints: (builder) => ({
    // GET /admin/products?includeInactive=true|false
    getProducts: builder.query<Product[], { includeInactive?: boolean } | void>(
      {
        query: (arg) => {
          const includeInactive = arg?.includeInactive ? "true" : "false";
          return `/products?includeInactive=${includeInactive}`;
        },
        transformResponse: (response: { status: string; data: Product[] }) =>
          response.data,
      },
    ),
    // GET /admin/products/:uuid?fullImages=true|false
    getProductById: builder.query<
      Product,
      { uuid: string; fullImages?: boolean }
    >({
      query: ({ uuid, fullImages }) => {
        const fullImagesParam = fullImages ? "true" : "false";
        return `/products/${uuid}?fullImages=${fullImagesParam}`;
      },
      transformResponse: (response: { status: string; data: Product }) =>
        response.data,
    }),
    // POST /admin/products – Создание товара. Для multipart/form-data нужно сформировать FormData на клиенте.
    createProduct: builder.mutation<Product, CreateProductRequest>({
      query: (newProduct) => {
        // Если API требует multipart/form-data, нужно преобразовать newProduct в FormData.
        const formData = new FormData();
        formData.append("name", newProduct.name);
        formData.append("description", newProduct.description);
        if (newProduct.composition)
          formData.append("composition", newProduct.composition);
        formData.append("price", newProduct.price.toString());
        formData.append("status", newProduct.status);
        formData.append("productTypeUuid", newProduct.productTypeUuid);
        formData.append("collectionUuid", newProduct.collectionUuid);
        if (newProduct.comment) formData.append("comment", newProduct.comment);
        // Добавляем варианты как JSON
        formData.append("variants", JSON.stringify(newProduct.variants));
        // Добавляем файлы изображений, если они есть
        newProduct.images.forEach((file) => {
          formData.append("images", file);
        });
        return {
          url: "/products",
          method: "POST",
          body: formData,
        };
      },
      transformResponse: (response: { status: string; data: Product }) =>
        response.data,
    }),
    // PATCH /admin/products/:uuid
    updateProduct: builder.mutation<Product, UpdateProductRequest>({
      query: ({ uuid, changes }) => ({
        url: `/products/${uuid}`,
        method: "PATCH",
        body: changes,
      }),
      transformResponse: (response: { status: string; data: Product }) =>
        response.data,
    }),
    // DELETE /admin/products/:uuid
    deleteProduct: builder.mutation<{ uuid: string }, string>({
      query: (uuid) => ({
        url: `/products/${uuid}`,
        method: "DELETE",
      }),
      transformResponse: (_: any, __: any, arg: string) => ({ uuid: arg }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
} = productsApi;
