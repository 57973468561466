import { JSX } from "react";
import "./FormWrap.css";

interface FormWrapProps {
  children: string | JSX.Element | JSX.Element[] | null;
}

export const FormWrap = ({ children }: FormWrapProps) => {
  return <section className="form-wrap">{children}</section>;
};
