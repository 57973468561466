import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ProductType } from "../../services/types/productType.type";

export const productTypesApi = createApi({
  reducerPath: "productTypesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ADMIN_API_URL, // "https://api.miyukon.store/admin"
    credentials: "include",
  }),
  endpoints: (builder) => ({
    getProductTypes: builder.query<ProductType[], void>({
      query: () => "/product-types",
      transformResponse: (response: {
        status: string;
        message: string;
        data: ProductType[];
      }) => response.data,
    }),
    getProductTypeById: builder.query<ProductType, string>({
      query: (uuid) => `/product-types/${uuid}`,
      transformResponse: (response: {
        status: string;
        message: string;
        data: ProductType;
      }) => response.data,
    }),
    createProductType: builder.mutation<ProductType, { name: string }>({
      query: (newType) => ({
        url: "/product-types",
        method: "POST",
        body: newType,
      }),
      transformResponse: (response: {
        status: string;
        message: string;
        data: ProductType;
      }) => response.data,
    }),
    updateProductType: builder.mutation<
      ProductType,
      { uuid: string; name: string }
    >({
      query: ({ uuid, name }) => ({
        url: `/product-types/${uuid}`,
        method: "PATCH",
        body: { name },
      }),
      transformResponse: (response: {
        status: string;
        message: string;
        data: ProductType;
      }) => response.data,
    }),
    deleteProductType: builder.mutation<{ uuid: string }, string>({
      query: (uuid) => ({
        url: `/product-types/${uuid}`,
        method: "DELETE",
      }),
      transformResponse: (_: any, __: any, arg: string) => ({ uuid: arg }),
    }),
  }),
});

export const {
  useGetProductTypesQuery,
  useGetProductTypeByIdQuery,
  useCreateProductTypeMutation,
  useUpdateProductTypeMutation,
  useDeleteProductTypeMutation,
} = productTypesApi;
