import { useState } from "react";
import { BorderWrap } from "../ui/wrappers/BorderWrap/BorderWrap";
import { GridWrap } from "../ui/wrappers/GridWrap/GridWrap";
import { TextButton } from "../ui/buttons/TextButton/TextButton";
import ModalWrap from "../ui/wrappers/ModalWrap/ModalWrap";

export const Products = () => {
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => setOpenModal(false);

  const addModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <TextButton
        text="Добавить новую вещь"
        color="black"
        margin="0 0 2rem 0"
        action={addModal}
      />
      <GridWrap>
        <BorderWrap>
          <div></div>
        </BorderWrap>
      </GridWrap>
      {openModal && (
        <ModalWrap action={closeModal}>
          <div></div>
        </ModalWrap>
      )}
    </>
  );
};
