import dashboard from "../assets/icons/dashboard.png";
import order from "../assets/icons/delivery.png";
import stock from "../assets/icons/warehouse.png";
import product from "../assets/icons/t-shirt.png";
import collections from "../assets/icons/collections.png";
import list from "../assets/icons/list.png";

export const menuItems = [
  {
    title: "Главная",
    link: "/",
    icon: dashboard,
    roles: ["superadmin", "admin", "manager", "warehouse"],
  },
  {
    title: "Заказы",
    link: "/orders",
    icon: order,
    roles: ["superadmin", "admin", "manager", "warehouse"],
  },
  {
    title: "Склад",
    link: "/stock",
    icon: stock,
    roles: ["superadmin", "admin", "warehouse"],
  },
  {
    title: "Товары",
    link: "/products",
    icon: product,
    roles: ["superadmin", "admin", "manager", "warehouse"],
  },
  {
    title: "Коллекции",
    link: "/collections",
    icon: collections,
    roles: ["superadmin", "admin", "manager"],
  },
  {
    title: "Типы товаров",
    link: "/types",
    icon: list,
    roles: ["superadmin", "admin"],
  },
];
