import { Logo } from "../../ui/Logo/Logo";
import { Account } from "../Account/Account";
import "./Header.css";

export const Header = () => {
  return (
    <header className="header">
      <Logo padding="0 2rem" />
      <Account />
    </header>
  );
};
