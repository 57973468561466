import { JSX } from "react";
import "./BlockWrap.css";

interface BlockWrapProps {
  children: string | JSX.Element | JSX.Element[] | null;
}

const BlockWrap = ({ children }: BlockWrapProps) => {
  return <section className="block-wrap">{children}</section>;
};

export default BlockWrap;
