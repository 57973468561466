import { NavLink } from "react-router";
import "./NavItem.css";
import { Icon } from "../images/Icon/Icon";

interface NavItemProps {
  title: string;
  link: string;
  icon: string;
}

export const NavItem = ({ title, link, icon }: NavItemProps) => {
  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        isActive ? "active nav-item" : "not-active nav-item"
      }
    >
      <Icon icon={icon} />
      <p className="nav-item-title">{title}</p>
    </NavLink>
  );
};
