import "./TextError.css";

interface TextErrorProps {
  text?: string;
  margin?: string;
}

export const TextError = ({ text, margin }: TextErrorProps) => {
  const styles = {
    margin: margin || "0",
  };

  return (
    <div style={styles} className="text-error">
      {text}
    </div>
  );
};
