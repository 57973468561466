import { BorderWrap } from "../ui/wrappers/BorderWrap/BorderWrap";
import { GridWrap } from "../ui/wrappers/GridWrap/GridWrap";

export const Orders = () => {
  return (
    <GridWrap>
      <BorderWrap>
        <div></div>
      </BorderWrap>
    </GridWrap>
  );
};
