import "./Logo.css";

interface LogoProps {
  fontSize?: number;
  padding?: string;
}

export const Logo = ({ fontSize, padding }: LogoProps) => {
  const styles = {
    padding: padding || "0",
  };

  const stylesTitle = {
    fontSize: fontSize ? `${fontSize}rem` : "2.4rem",
    lineHeight: fontSize ? `${fontSize}rem` : "2.4rem",
  };

  const stylesSubtitle = {
    fontSize: fontSize ? `${fontSize / 2}rem` : "1.2rem",
    lineHeight: fontSize ? `${fontSize / 2}rem` : "1.2rem",
  };

  return (
    <div className="logo" style={styles}>
      <p className="logo-title" style={stylesTitle}>
        MIYUKON
      </p>
      <p className="logo-subtitle" style={stylesSubtitle}>
        admin
      </p>
    </div>
  );
};
