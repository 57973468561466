import "./UnauthorizedInfo.css";

export const UnauthorizedInfo = () => {
  return (
    <>
      <p className="unauthorized-info">401</p>
      <p>У вас нет доступа к этому разделу</p>
    </>
  );
};
