import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { BaseLayout } from "../layout/BaseLoyout";
import { Dashboard } from "../pages/Dashboard";
import { Products } from "../pages/Products";
import { Stock } from "../pages/Stock";
import { Orders } from "../pages/Orders";
import { Collections } from "../pages/Collections";
import { Types } from "../pages/Types";
import { Login } from "../pages/Login";
import { NoPage } from "../pages/NoPage";
import PublicRoute from "./PublicRoute";
import { Unauthorized } from "../pages/Unauthorized";

const AppRoutes = () => (
  <Routes>
    {/* Публичные маршруты */}
    <Route
      path="/login"
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />
    <Route path="/unauthorized" element={<Unauthorized />} />

    {/* Защищённые маршруты */}
    <Route
      element={
        <PrivateRoute
          allowedRoles={["admin", "manager", "superadmin", "warehouse"]}
        />
      }
    >
      <Route element={<BaseLayout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/stock" element={<Stock />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/products" element={<Products />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/types" element={<Types />} />
      </Route>
    </Route>

    {/* Маршрут для несуществующих страниц */}
    <Route path="*" element={<NoPage />} />
  </Routes>
);

export default AppRoutes;
