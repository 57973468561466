import { Preloader } from "../ui/Preloader/Preloader";
import { FormWrap } from "../ui/wrappers/FormWrap/FormWrap";

export const LoadPage = () => {
  return (
    <FormWrap>
      <Preloader />
    </FormWrap>
  );
};
