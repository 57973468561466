import { JSX } from "react";
import "./GridWrap.css";

interface GridWrapProps {
  children: string | JSX.Element | JSX.Element[] | null;
  column?: 2 | 3 | 4;
}

export const GridWrap = ({ children, column = 4 }: GridWrapProps) => {
  return <div className={`grid-wrap grid-wrap-${column}`}>{children}</div>;
};
