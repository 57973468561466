import { TextError } from "../../errors/TextError/TextError";
import "./TextInput.css";

interface TextInputProps {
  type?: "text" | "email" | "password";
  name: string;
  label?: string;
  width?: string;
  margin?: string;
  value: string;
  action: (e: React.ChangeEvent<HTMLInputElement>) => void;
  textError?: string;
}

export const TextInput = ({
  type = "text",
  name,
  label,
  width,
  margin,
  value,
  action,
  textError,
}: TextInputProps) => {
  const styles = {
    width: width || "20rem",
    margin: margin || "0",
  };

  return (
    <div className="text-input" style={styles}>
      {label && <label htmlFor={name}>{label}</label>}
      <input id={name} type={type} value={value} onChange={action} />
      <TextError text={textError} />
    </div>
  );
};
