import { JSX } from "react";
import "./ModalWrap.css";
import { IconButton } from "../../buttons/IconButton/IconButton";
import closeButton from "../../../../assets/icons/close.png";

interface ModalWrapProps {
  children: string | JSX.Element | JSX.Element[] | null;
  title?: string;
  action: () => void;
}

const ModalWrap = ({ children, title, action }: ModalWrapProps) => {
  const closeModal = () => action();

  return (
    <section className="modal-wrap">
      <div className="modal-window">
        <IconButton icon={closeButton} action={closeModal} />
        {title && <p className="modal-title">{title}</p>}
        {children}
      </div>
    </section>
  );
};

export default ModalWrap;
